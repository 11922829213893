@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  width: 100%;
  padding: 2rem 4rem 2rem;
}

.header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-nav {
  display: flex;
  gap: 0.3rem;
  font-size: 18px;
  font-weight: 500;
}

.info-wrapper {
  width: 100%;
  padding: 4rem 2rem 2rem;
}

.info-head {
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
  color: rgba(33, 62, 122, 1);
}

.info-txt {
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 1);
}

.features-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
}

.listed-features-container > p,
.specifications_container > p {
  font-size: 24px;
  font-weight: 600;
  color: rgba(33, 62, 122, 1);
  margin-bottom: 0.8rem;
}

.listed-features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 18px;
  font-weight: 400;
  color: rgba(33, 33, 33, 1);
}

.specifications_container {
  margin-top: 4rem;
}

.spec-table {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.spec-row {
  display: contents;
}

.spec-row.header-row {
  font-weight: 600;
}

.spec-item,
.spec-detail {
  padding: 12px;
  font-size: 16px;
  text-align: left;
}

.spec-item.gray,
.spec-detail.gray {
  background-color: rgba(240, 244, 251, 0.6);
}

.footerContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem;
  padding-top: 1.5rem;
  margin-top: 3rem;
  border-top: 8px solid rgba(33, 62, 122, 1);
}

.footerContainer > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 16px; 
  font-weight: 400;
}

@media (max-width: 600px) {
  .spec-table {
    grid-template-columns: 1fr 1fr;
  }
  .spec-item,
  .spec-detail {
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 390px) {
  .spec-table {
    grid-template-columns: 1fr 1fr;
  }
  .header-container {
    gap: 1.5rem;
  }

  .features-img {
    width: 270px;
  }

  .listed-features {
    text-align: left;
  }

  .spec-detail {
    text-align: left;
    width: 160px;
  }
}

@media (max-width: 480px) {
  body {
    padding: 2rem 2.5rem 2rem;
  }

  .header-nav {
    font-size: 14px;
  }

  .info-wrapper {
    width: 100%;
    padding: 3rem 1rem 2rem;
  }

  .info-container {
    margin: 0 auto;
  }
  .info-head {
    font-size: 25px;
    line-height: normal;
  }
  .info-txt {
    font-size: 14px;
  }
  .features-wrapper {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .listed-features-container > p {
    margin-bottom: 0.5rem;
  }

  .features-img {
    max-width: 300px;
  }
}

@media (min-width: 481px) and (max-width: 540px) {
  .features-wrapper {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .features-img {
    width: 400px;
  }
}

@media (min-width: 541px) and (max-width: 767px) {
  .features-wrapper {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .features-img {
    width: 400px;
  }

  .listed-features {
    margin-right: 8rem;
  }
}

@media (min-width: 768px) and (max-width: 853px) {
  .features-wrapper {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .features-img {
    width: 610px !important;
  }

  .listed-features {
    margin-right: 8rem;
  }
}

@media (min-width: 812px) and (max-width: 1023px) {
  .features-wrapper {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .features-img {
    width: 700px;
  }

  .listed-features {
    margin-right: 15rem;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .features-wrapper {
    gap: 1rem;
  }

  .info-wrapper {
    padding: 4rem 1rem 4rem;
  }
  .features-img {
    width: 500px;
  }

  .listed-features {
    gap: 0.5rem;
    font-size: 14px;
  }
}
